import Vue from 'vue'
import VueRouter from 'vue-router'
import {store} from '@/plugins/store'

Vue.use(VueRouter)

const routes = [
    {
        name: "Home",
        path: '/',
        redirect: {path: '/users'},
        component: () =>
            import ('@/views/pages/Home.vue'),
        meta: {
            public: false,
            transition: 'zoom',
            requiresAuth: true
        }
    }, {
        name: "Users",
        path: '/users',
        component: () =>
            import ('@/views/tables/Users'),
        meta: {
            public: false,
            transition: 'zoom',
            requiresAuth: true
        }
    }, {
        name: "User",
        path: '/users/:user',
        component: () =>
            import ('@/views/pages/User'),
        meta: {
            public: false,
            transition: 'zoom',
            requiresAuth: true
        }
    }, {
        name: "raelics",
        path: '/tokens',
        component: () =>
            import ('@/views/tables/Tokens'),
        meta: {
            public: false,
            transition: 'zoom',
            requiresAuth: true
        },

    },
    {
        path: '/token/:token',
        name: 'raelic',
        component: () =>
            import ('@/views/pages/Token'),
        meta: {
            public: false,
            transition: 'zoom',
            requiresAuth: true
        }
    },
    {
        name: "Reports",
        path: '/reports',
        component: () =>
            import ('@/views/pages/Reports'),
        meta: {
            public: false,
            transition: 'zoom',
            requiresAuth: true
        }
    },
    {
        name: "TokenContracts",
        path: '/token-contracts',
        component: () =>
            import ('@/views/pages/TokenContracts'),
        meta: {
            public: false,
            transition: 'zoom',
            requiresAuth: true
        }
    }, {
        name: "CreateTokenContract",
        path: '/token-contracts/new',
        component: () =>
            import ('@/views/pages/CreateTokenContract'),
        meta: {
            public: false,
            transition: 'zoom',
            requiresAuth: true
        }
    }, {
        name: "TokenContract",
        path: '/token-contracts/:tokenContractId',
        component: () =>
            import ('@/views/pages/TokenContract'),
        meta: {
            public: false,
            transition: 'zoom',
            requiresAuth: true
        }
    }, {

        name: "Login",
        path: "/auth/login",
        component: () =>
            import ('@/views/pages/Login.vue'),
        meta: {
            public: true,
        }
    },
    {

        name: "Error404",
        path: "/error/error404",
        component: () =>
            import ('@/views/error/Error404.vue'),
        meta: {
            public: true,
        }
    }, {

        name: "Error505",
        path: "/error/error505",
        component: () =>
            import ('@/views/error/Error505.vue'),
        meta: {
            public: true,
        }
    }, {

        name: "Error400",
        path: "/error/error400",
        component: () =>
            import ('@/views/error/Error400.vue'),
        meta: {
            public: true,
        }
    }, {

        name: "Error500",
        path: "/error/error500",
        component: () =>
            import ('@/views/error/Error500.vue'),
        meta: {
            public: true,
        }
    }, {
        name: "Wallets",
        path: '/wallets',
        component: () =>
            import ('@/views/tables/Wallets'),
        meta: {
            public: false,
            transition: 'zoom',
            requiresAuth: true
        }
    }, {
        name: "Transactions",
        path: '/transactions',
        component: () =>
            import ('@/views/tables/Transactions'),
        meta: {
            public: false,
            transition: 'zoom',
            requiresAuth: true
        }
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL || '/',
    routes
})

router.beforeEach((to, from, next) => {
    store.commit('initIsLoggedIn')
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.state.logged_in) {
            next({path: '/auth/login'})
        } else {
            next()
        }
    } else {
        if (!store.state.logged_in) {
            next()
        } else {
            next({path: '/'})
        }
    }
})

export default router
